import { useState } from "react"
import styles from "./video.module.css"
import Play from "@/common/icons/play"
import Image from "next/legacy/image"
import ReactPlayer from "react-player/youtube"
import { usePropsObject } from "@/lib/contexts/appContext"
import classNames from "classnames"

export default function Video({
  data,
  className,
  figureClasses,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  hovered = false
}) {
  const [showPlayButton, setPlayButton] = useState(true)

  const playVideo = () => {
    setPlayButton(false)
  }

  const { theme } = usePropsObject()
  const figClasses = [
    "relative w-full h-full aspect-w-16 aspect-h-9",
    figureClasses
  ]

  const buttonClasses = [
    "w-16 h-16 md:w-24 md:h-24 text-center absolute top-1/2 left-1/2 z-20 focus:outline-none",
    styles.playButton
  ]

  const buttonsInnerSpanClasses = [
    "block absolute top-0 left-0 w-full h-full rounded-full",
    styles.btnColor,
    styles.buttonAnimation,
    theme?.accentColor
  ]

  return (
    <div
      className={classNames([className, styles.video, { hover: hovered }])}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}>
      <figure className={classNames(figClasses)}>
        {showPlayButton && (
          <>
            <div className={styles.overlay}></div>
            <button className={classNames(buttonClasses)} onClick={playVideo}>
              <span className={classNames(buttonsInnerSpanClasses)} />
              <Play className="w-4 md:w-[22px] h-auto ml-6.5 md:ml-10" />
            </button>
          </>
        )}
        {data.poster && showPlayButton && (
          <Image
            className="object-cover object-center w-full h-full"
            src={data.poster.url}
            alt={data.poster.alt}
            sizes={data.poster.sizes}
            layout="fill"
            loading="lazy"
          />
        )}
        {!showPlayButton && (
          <ReactPlayer
            url={data.url}
            playing={true}
            controls={true}
            width="100%"
            height="100%"
          />
        )}
      </figure>
    </div>
  )
}
