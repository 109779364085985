import Image from "next/legacy/image"
import styles from "@/common/partials/video/video.module.css"
import Play from "@/common/icons/play"
import { usePropsObject } from "@/lib/contexts/appContext"
import cx from "classnames"

function ImageVideo({
  data,
  className,
  aspectRatio = false,
  hovered = false,
  onMouseEnter = () => {},
  onMouseLeave = () => {}
}) {
  const imageSettings = {
    className: "object-cover object-center",
    src: data.image.url,
    alt: data.image.alt,
    loading: "lazy"
  }

  const { theme } = usePropsObject()
  const buttonClasses = cx(
    `w-16 h-16 md:w-24 md:h-24 text-center absolute top-1/2 left-1/2 z-20 focus:outline-none`,
    styles.playButton
  )
  const buttonsInnerDivClasses = cx(
    `block absolute top-0 left-0 w-full h-full rounded-full`,
    styles.buttonAnimation,
    styles.btnColor,
    theme?.accentColor
  )

  if (!aspectRatio) {
    imageSettings.width = data.image.width
    imageSettings.height = data.image.height
    imageSettings.sizes = data.image.sizes
  } else {
    imageSettings.layout = "fill"
    imageSettings.sizes = data.image.sizes
  }
  return (
    <a
      href={data.url}
      className={`block ${className} ${styles.video} ${hovered ? "hover" : ""}`}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}>
      <figure className={`relative ${aspectRatio ? aspectRatio : ""}`}>
        <div className={`w-full h-full ${styles.overlay}`}></div>
        <button className={buttonClasses}>
          <div className={buttonsInnerDivClasses} />
          <Play className="w-4 md:w-[22px] h-auto ml-6.5 md:ml-10" />
        </button>
        {data.image.url && (
          <div className={styles.blockChildImage}>
            <Image {...imageSettings} />
          </div>
        )}
      </figure>
    </a>
  )
}

export default ImageVideo
